<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#">Employee</b-breadcrumb-item>
                <b-breadcrumb-item href="#" active>Add Employee</b-breadcrumb-item>
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-card type="default" header-classes="bg-transparent">
              <b-form style="text-align: left">
                <b-row>
                    <b-col md="3" class="mt-4">
                        <label>Name*</label>
                        <b-input v-model="name" type="text" placeholder="Add Name"></b-input>
                        <!-- <b-form-text>
                          Employee full name.
                        </b-form-text> -->
                    </b-col>
                    <b-col md="3" class="mt-4">
                        <label>Email</label>
                        <b-input v-model="email" type="email" placeholder="Add Email" />
                    </b-col>
                    <b-col md="3" class="mt-4">
                        <label>Phone No*</label>
                        <b-input v-model="phone" type="number" placeholder="Add Phone" label="Phone:"/>
                    </b-col>
                    <b-col md="3" class="mt-4">
                        <label>Password*</label>
                        <b-input v-model="password" placeholder="Password" label="Password:"/>
                    </b-col>
                    <b-col md="3" class="mt-4">
                        <label>Employee Type*</label>
                        <b-input-group>
                          <b-form-select v-model="selectedEmpType" :options="empType"></b-form-select>
                          <b-input-group-append>
                              <b-button @click="$router.push({name: 'employee-type'})" variant="outline-primary">
                                <i class="fas fa-plus"></i>
                              </b-button>
                          </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col md="3" class="mt-4">
                        <label>Employee Location*</label>
                        <b-input-group>
                          <b-form-select v-model="location" :options="empLocation"></b-form-select>
                          <b-input-group-append>
                            <b-button @click="$router.push({name: 'location'})" variant="outline-primary"><i class="fas fa-plus"></i></b-button>
                          </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col md="3" class="mt-4">
                        <label>Dath of Birth</label>
                        <b-input type="date" v-model="dob" value="dd-mm-yyyy" id="example-date-input"/>
                    </b-col>
                    <b-col md="3" class="mt-4">
                        <label>Address</label>
                        <b-input type="text" v-model="address" placeholder="Add Address" />
                    </b-col>
                </b-row>
                <b-row>
                  <b-col md="9"></b-col>
                  <b-col md="3" class="mt-4"><base-button @click="employee()" block type="primary">Add Employee</base-button></b-col>
                </b-row>
                <b-row>
                  <b-col md="9"></b-col>
                  <b-col md="3" class="mt-4">
                    <base-button block type="primary" @click="$router.push({name: 'employee-list'})">View Employees</base-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
        </b-container>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import { API_URL } from '../../../router/api'
// import Footer from '@/Starter/SampleFooter'
export default {
  name: 'add-employee',
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data () {
    return {
      isActive: false,
      selectedEmpType: null,
      selectedEmpLocation: null,
      name: null, 
      email: null, 
      phone: null, 
      password: null,
      selectedEmpType: null,
      location: null,
      dob: null,
      address: null,
      empId: this.$route.params.empId
    }
  },
  computed: {
    emp() {
      console.log(this.empId);
      return this.$store.state.employeeList.filter((data) => {
        return data.employee_id === this.empId
      })
    },
    empType() {
      return this.$store.state.employeeTypeList
    },
    empLocation() {
      return this.$store.state.locationListData
    }
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    employee() {
      const self = this;
      const api = {
        callback() {
          let dataPeram = {
            name: self.name, 
            email: self.email, 
            phone: self.phone, 
            password: self.password,
            selectedEmpType: self.selectedEmpType,
            location: self.location,
            dob: self.dob,
            address: self.address
          }
          self.axios.post(API_URL+'admin_employee_add',dataPeram)
          .then((response) => {
          let obj = response.data
          console.log(obj);
          let final = obj.status == true ? self.$swal.fire({icon: 'success',title: `${obj.message}`,toast: true,
            position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
          }): self.$swal.fire({icon: 'error',title: `${obj.message}`,toast: true,
            position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
          })
          self.$store.dispatch('getEmployeeList');
          })
          console.log(dataPeram);
          self.name= null 
          self.email= null 
          self.phone= null 
          self.password= null
          self.selectedEmpType= null
          self.location= null
          self.dob= null
          self.address= null
        }
      }
      const validation =  this.name == null ? this.$swal.fire({icon: 'error',title: 'Name Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): this.phone == null ? this.$swal.fire({icon: 'error',title: 'Phone Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): this.password == null ? this.$swal.fire({icon: 'error',title: 'Password Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): this.selectedEmpType == null ? this.$swal.fire({icon: 'error',title: 'Employee Type Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): this.location == null ? this.$swal.fire({icon: 'error',title: 'Location Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): api.callback()
    }
  },
  mounted() {
    this.name = this.emp.length > 0 ? this.emp[0].employee_name : null
    this.email = this.emp.length > 0 ? this.emp[0].employee_email : null
    this.phone = this.emp.length > 0 ? this.emp[0].employee_phone : null
    this.selectedEmpType = this.emp.length > 0 ? this.emp[0].employee_type : null
    this.location = this.emp.length > 0 ? this.emp[0].employee_location : null
    this.dob = this.emp.length > 0 ? this.emp[0].employee_dob : null
    this.address = this.emp.length > 0 ? this.emp[0].employee_address : null
    console.log(this.emp);
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
</style>
